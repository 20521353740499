import React,{useRef,useState,useEffect} from 'react';
import {DataTable,QuotationInfoCard} from '../../Components'
import { useNavigate } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";

import {NumberFormat,MobileFormat,DateThaiFormat,DateFormat,DateThai,GetRole} from '../../Utils/Function';
import {QuotationStatusLabel} from '../../Utils/Status'
import { createRoot } from 'react-dom/client';

import Swal from 'sweetalert2';
import API from '../../API';
import QuotationCreate from './QuotationCreate';
import {QuotationPDF} from "../../Components/Quotation";

import "react-datepicker/dist/react-datepicker.css";
import '../../Assets/CSS/Horapa.css';

const PageQuotationList =()=> {
    const RedirectTo = useNavigate()
    const { register, getValues, setValue } = useForm();

    const ref = useRef();
    const _OrderCard = useRef();
    const _QuotationCreate = useRef();
    const _QuotationInfoCard = useRef();
    const [StatusQuotationList,setStatusQuotationList] = useState([]);

    useEffect(() => {
        (async () => {
            await _LoadQuotationStatus();
        })();

        return ()=>{ }
    }, []);
    const _LoadQuotationStatus = async()=>{
        API({method : "get",url:"/Search/Status/Quotation"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setStatusQuotationList(res.data.Data);
        }).catch(res => {
            return;
        });
    }
    
    const _QuotationInfo = (QuotationID) =>{

    }

    const _OrderInfo = (OrderID)=>{
        _OrderCard.current?.Show(OrderID);
    }
    const _configs = {
        "order": [[9, "desc"]],
        pageLength: 25,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/Quotation/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
            d.Status = getValues('Status');
        }
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => _QuotationInfoCard.current?.Show(d)}>Info</button>
            <button type='button' title='รายละเอียด' className='btn btn-warning text-white' onClick={() => QuotationPDF(d)}>พิมพ์</button>

        </div>
    )
    const _column = [
        { data: "QuotationCode", name: "QuotationCode", autoWidth: true },
        { data: "CustomerCode", name: "CustomerCode", autoWidth: true },
        { data: "CustomerName", name: "CustomerName", autoWidth: true },
        { data: "ContactPhoneMain", name: "ContactPhoneMain", autoWidth: true },
        {data: "QuoteDate", name: "QuoteDate", autoWidth: true,render(data){
            return DateThaiFormat(data);
            //return data;
        }},
        {data: "EventDate", name: "EventDate", autoWidth: true,render(data){
            return DateThaiFormat(data);
            return data;
        }},
        {data: "DepositDate", name: "DepositDate", autoWidth: true,render(data){
            if(data != null) return DateFormat(data); else return '-';
        }},
        {data: "TotalAmount", name: "TotalAmount", autoWidth: true,render(data){
            return NumberFormat(data);
        }},
        {data: "Status", name: "Status", autoWidth: true,render(data){
            return QuotationStatusLabel(data);
        }},
        {data: "UpdateDate", name: "UpdateDate", autoWidth: true,render(data){
            return DateThaiFormat(data,true);
        }},
        {
            data: "QuotationId", name: "QuotationID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]
    
    const _UpdateTable =()=>{
        ref.current?.load();
    }
    const _CreateQuotation = ()=>{
        _QuotationCreate.current?.Show();
    }
    const _Customer = ()=>{
        RedirectTo('/Customer');
    }


    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>จัดการใบเสนอราคา</h3>
                            </div>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                                <div className='row d-flex justify-content-end'>
                                    <div className='col-6 col-lg-5 mt-2 text-end'>
                                        <div className="input-group mb-3">
                                            <select className='form-control text-center' {...register("Status", { required: false })} defaultValue={0}>
                                                <option value={0} selected>ทั้งหมด</option>
                                                {StatusQuotationList.map((v,i)=>{
                                                    return <option key={i} value={v.QuotationStatusId}>{v.QuotationStatusName}</option>
                                                })}
                                            </select>
                                            <button type='button' style={{'width':'80px'}} className='btn btn-info text-white' onClick={()=>ref.current.load()}>ค้นหา</button>
                                            <button type='button'  className='btn btn-warning text-white' onClick={()=>{_Customer();}}>จัดการข้อมูลลูกค้า</button>
                                            <button type='button'  className='btn btn-primary text-white' onClick={()=>{_CreateQuotation();}}>สร้างใบเสนอราคา</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-lg-12 table-responsive">
                                        <table id="HomeOrderView" className='table w-100' role='grid'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center textcenter">เลขที่ใบเสนอราคา</th>
                                                    <th className="text-center textcenter">รหัสลูกค้า</th>
                                                    <th className="text-center textcenter">ลูกค้า</th>
                                                    <th className="text-center textcenter">เบอร์โทร</th>
                                                    <th className="text-center textcenter">วันที่เสนอราคา</th>
                                                    <th className="text-center textcenter">วันที่จัดงาน</th>
                                                    <th className="text-center textcenter">วันที่ชำระมัดจำ</th>
                                                    <th className="text-center textright">ยอดสรุป</th>
                                                    <th className="text-center textcenter">สถานะ</th>
                                                    <th className="text-center textcenter">วันที่แก้ไข</th>
                                                    <th className="text-center no-sort"></th>
                                                </tr>
                                            </thead>
                                            <DataTable TableID={"HomeOrderView"} Column={_column} Configs={_configs} ref={ref} ajax={_ajax} ColumnDefs={_ColumnDefs} />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <QuotationCreate ref={_QuotationCreate} update={()=>{_UpdateTable();}}/>
            <QuotationInfoCard ref={_QuotationInfoCard}/>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageQuotationList)
