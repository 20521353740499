import React,{useRef} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import API from '../API';
import Swal from 'sweetalert2';
import '../Assets/CSS/Horapa.css';
import { GetRole, RootURL } from '../Utils/Function';
import {UserChagePassword} from '../Components';
const Header =()=> {
    const _UserChangePassword = useRef();

    const Logout = async()=>{
        API({method : "get",url:"/Authorized/Logout"}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                return;
            }
            sessionStorage.clear();
            window.location.href = '/';
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            }).then(()=>{
                sessionStorage.clear();
                window.location.href = '/';
            })
        });
    }

    return (
        <>
        <header>
            <Navbar collapseOnSelect expand="lg" className="navbar navbar-light header">
                <Container fluid>
                    <Navbar.Brand href={RootURL()}>
                        <img className='navbar-brand' src={require('../Assets/Images/Logo/horapa-logo_whiteC.png')} alt='Logo'/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="me-auto">
                            <Nav.Link href="#link">
                            <div className='text-end text-white'></div>
                            </Nav.Link>
                        </Nav>
                        <Nav style={{'marginRight':'150px'}}>
                            <Nav.Item className='text-end text-white mt-4' style={{'marginRight':'20px'}}>{sessionStorage.getItem('Name')}</Nav.Item>
                            <NavDropdown title={(<img src={require('../Assets/Images/Avatar/avatar.png')} className='avatar' alt='Avatar'/>)}>
                                <NavDropdown.Item href="/Calendar">หน้าหลัก</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/Quotation">จัดการใบเสนอราคา</NavDropdown.Item>
                                <NavDropdown.Item href="/Customer">จัดการข้อมูลลูกค้า</NavDropdown.Item>
                                <NavDropdown.Item href="/Calendar">ปฎิทิน</NavDropdown.Item>

                                <NavDropdown.Divider />

                                {(GetRole() === 99?<NavDropdown.Item href="/Package">จัดการแพ็คเก็จ</NavDropdown.Item>:"")}
                                {(GetRole() === 99?<NavDropdown.Item href="/Product">จัดการสินค้า</NavDropdown.Item>:"")}
                                {(GetRole() === 99?<NavDropdown.Item href="/Food">จัดการรายการอาหาร</NavDropdown.Item>:"")}
                                {(GetRole() === 99?<NavDropdown.Item href="/BankAccount">จัดการข้อมูลธนาคาร</NavDropdown.Item>:"")}
                                {(GetRole() === 99?<NavDropdown.Item href="/QuotationRemark">จัดการหมายเหตุใบเสนอราคา</NavDropdown.Item>:"")}
                                {(GetRole() === 99?<NavDropdown.Item href="/Promotion">จัดการโปรโมชั่น</NavDropdown.Item>:"")}

                                {(GetRole() === 99?<NavDropdown.Divider />:"")}
                                {(GetRole() === 99?<NavDropdown.Item href="/User">จัดการผู้ใช้งาน</NavDropdown.Item>:"")}
                                {(GetRole() === 99?<NavDropdown.Item href="/User/Online">ข้อมูลผู้ใช้งาน Online</NavDropdown.Item>:"")}

                                {(GetRole() === 99?<NavDropdown.Divider />:"")}
                                <NavDropdown.Item onClick={()=>{_UserChangePassword.current?.Show();}}>เปลี่ยนรหัสผ่าน</NavDropdown.Item>
                                <NavDropdown.Item onClick={()=>{Logout();}}>
                                    ออกจากระบบ
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
        <UserChagePassword ref={_UserChangePassword} />
        </>
    )
}

export default Header;

