import React,{useRef,useState,useEffect} from 'react';
import {DataTable,QuotationInfoCard} from '../../Components'
import { Controller, set, useForm } from "react-hook-form";
import { createRoot } from 'react-dom/client';
import { DateFormat } from '../../Utils/Function';
import Swal from 'sweetalert2';
import API from '../../API';
import PromotionInfo from './PromotionInfo';
const PagePromotionList =()=> {
    const { register, getValues, setValue } = useForm();

    const ref = useRef();
    const _PromotionInfoRef = useRef();
    useEffect(() => {
        (async () => {
            //await _LoadQuotationStatus();
        })();

        return ()=>{ }
    }, []);

    
    const _configs = {
        "order": [[0, "asc"]],
        pageLength: 10,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/Promotion/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
            d.DiscountType = getValues('DiscountType');
        }
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => {_PromotionInfoRef.current?.Show(d);}}>Info</button>
            <button type='button' title='ลบ' className='btn btn-danger text-white' onClick={() => {_DeletePromotion(r);}}>ลบ</button>
        </div>
    )
    const _column = [
        { data: "PromotionCode", name: "PromotionCode", autoWidth: true },
        { data: "PromotionName", name: "PromotionName", autoWidth: true },
        { data: "Description", name: "Description", autoWidth: true },
        { data: "DiscountType", name: "DiscountType", autoWidth: true,render(data){
            switch(data){
                case 1:return "ส่วนลด";
                case 2:return "ส่วนลดเปอร์เซ็น";
                case 3:return "ฟรีค่าขนส่ง";
                case 4:return "ของแถม";

                default:return '-';
            }
        }},
        {data: "UpdateDate", name: "UpdateDate", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        {
            data: "PromotionId", name: "PromotionID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]
    const _DeletePromotion = async(val)=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องลบ '+val.PromotionName+' หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ลบ',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            API({method : "delete",url:"/Promotion/"+val.PromotionId}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                ref.current?.load();
            }).catch(res => {
                return;
            });
        });
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>จัดการโปรโมชั่น</h3>
                            </div>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                                <div className='row d-flex justify-content-end'>
                                    <div className='col-12 col-lg-3 mt-2 text-end'>
                                        <button type='button'  className='btn btn-primary text-white' onClick={()=>{_PromotionInfoRef.current?.Show()}}>เพิ่มโปรโมชั่น</button>

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-lg-12 table-responsive">
                                        <table id="PromotionListView" className='table w-100' role='grid'>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center textcenter">รหัสโปรโมชั่น</th>
                                                        <th className="text-center textcenter">ชื่อโปรโมชั่น</th>
                                                        <th className="text-center textcenter">รายละเอียด</th>
                                                        <th className="text-center textcenter">รูปแบบส่วนลด</th>
                                                        <th className="text-center textcenter">วันที่แก้ไข</th>
                                                        <th className="text-center no-sort"></th>
                                                    </tr>
                                                </thead>
                                            <DataTable TableID={"PromotionListView"} Column={_column} Configs={_configs} ref={ref} ajax={_ajax} ColumnDefs={_ColumnDefs} />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        <PromotionInfo ref={_PromotionInfoRef} update={()=>{ref.current?.load();}}/>
        </>
    )
}
export default React.memo(PagePromotionList)