import React from 'react';
import {Col,Row,Form} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import API from '../../API';
import '../../Assets/CSS/Horapa.css';
const Login =()=> {
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();

    const _Login=()=>{
        API({method : "post",url:"/Authorized/Login",data:{UserName : getValues("UserName"),Password : getValues("Password")}, headers: { "Content-Type": "multipart/form-data" }}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.data.Message,
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }

            sessionStorage.setItem("Authorization", res.data.Data.Authorization);

            sessionStorage.setItem("UserID", res.data.Data.UserID);
            sessionStorage.setItem("UserName", res.data.Data.Username);
            sessionStorage.setItem("Name", res.data.Data.Name);
            sessionStorage.setItem("Role", res.data.Data.Role);
            window.location ='./Calendar';

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            })
        });
    }
    return (
        <>
        <section className='sign-in-page'>
            <div className='container-fluid p-0'>
            <Row className='no-gutters d-flex justify-content-center'>
                    <Col lg={3} col={8} sm={10} xs={10} className='align-self-center  mt-5 mb-5 login-box'>
                        <Form onSubmit={handleSubmit(_Login)}>
                            <div className='sign-in-from ml-4 mr-4 mt-4 mb-4'>
                                <div className='row'>
                                    <div className='col-12 text-center'>
                                        <img src={require('../../Assets/Images/Logo/horapa-logo_goldA.png')} style={{'width':'252px','height':'237px'}} alt='Logo'/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 text-center'>
                                        <h3 className='text-white fs-3'> Customer Relationship Management</h3>
                                    </div>
                                </div>
                                <div className='row justify-content-md-center mt-4'>
                                    <div className='col-8'>
                                        <div className="mb-3">
                                            <label htmlFor="Username" className="form-label text-white">ชื่อผู้ใช้งาน</label>
                                            <Controller name="UserName" control={control} rules={{required:true}} render={({field})=>(
                                                <input autoFocus type="text" id="Username" className="form-control" value={field.value} aria-describedby={field.name} placeholder="Username" autoComplete="off" onChange={(e)=>field.onChange(e)} defaultValue={field.value} />
                                            )} />
                                            {errors.UserName && errors.UserName.type == 'required' && <small className="form-text alert alert-danger">โปรดตรวจสอบ Username</small> }
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Passwprd" className="form-label text-white">รหัสผ่าน</label>
                                            <Controller name="Password" control={control} rules={{required:true}} render={({field})=>(
                                                <input type="password" className="form-control" value={field.value} aria-describedby={field.name} placeholder="Password" autoComplete="off" onChange={(e)=>field.onChange(e)} defaultValue={field.value} />
                                            )} />
                                            {errors.Password && errors.Password.type == 'required' && <small className="form-text alert alert-danger">โปรดตรวจสอบ Password</small> }
                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-md-center mt-4 mb-5'>
                                    <div className='col-8 text-center'>
                                        <button type='submit' className='btn btn-lg btn-login'>เข้าสู่ระบบ</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </section>
        </>
    )
}

export default React.memo(Login)
