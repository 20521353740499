import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import { Controller, useForm } from "react-hook-form";

import API from '../../API';
import { DateFormat,DateFormatJSON, DateThai,MobileFormat, NumberFormat,CleanMobile, TimeFormat,APIURL,GetAuthorization } from '../../Utils/Function';
import {SearchSubDistrict,SearchCustomer} from '../../Utils/Search';
import { UploadCard,DeleteFile,FilePreviewCard } from '../../Components';
import parse from 'html-react-parser'
import moment from 'moment';
import AsyncSelect from 'react-select/async';

const PackageCreate = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();

    const [ModalState,setModalState] = useState(false);
    const [_PackageGroupList,setPackageGroupList] = useState([]);
    const [_PriceList,setPriceList]=  useState([]);
    const [_FoodMenuList,setFoodMenuList] = useState([]);
    const [_FoodGroupList,setFoodGroupList] = useState([]);
    const [_Detail,setDetail] = useState(null);
    const _UploadRef = useRef();
    const _FilePreviewRef = useRef();
    const [_FileList,setFileList] = useState([]);
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(PackageID){            
            _ClearValuAction();
            _GetPackageGroupList();
            setFoodMenuList([]);
            setPriceList([]);
            setDetail(null);
            _Initial();

            if(PackageID === undefined){
                setModalState(true);
            }else{
                _LoadDetail(PackageID);
            }
        }

      }));
    const _Initial = ()=>{
        setFileList([]);
        API({method : "get",url:"/Package/FoodMenu"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setFoodMenuList(res.data.Data);
        }).catch(res => {
            return;
        });
        
        API({method : "get",url:"/Search/Menu/Group"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setFoodGroupList(res.data.Data);
        }).catch(res => {
            return;
        });  
    }
    const _LoadDetail = async (PackageID)=>{
        setFileList([]);
        API({method : "get",url:"/Package/"+PackageID}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            setDetail(res.data.Data);
            setFoodMenuList(res.data.Data.Menu);
            setPriceList(res.data.Data.Prices);
            setValue('PackageCode',res.data.Data.PackageCode);
            setValue('QuantityLimit',res.data.Data.QuantityLimitProfile);
            setValue('PackageName',res.data.Data.PackageName);
            setValue('PackageGroupID',res.data.Data.PackageGroupId);
            setValue('Price',res.data.Data.Price);
            setValue('Description',res.data.Data.Description);
            setValue('Remark',res.data.Data.Remark);
            setValue('GLRefCode',res.data.Data.GLRefCode);

            _UploadGetFileLsit(PackageID);
            setModalState(true);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: res.data.Message,
            });
            return;
        });
    }
    const _GetPackageGroupList = async()=>{
        setPackageGroupList([]);
        API({method : "get",url:"/Search/Package/Group"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setPackageGroupList(res.data.Data);
        }).catch(res => {
            return;
        });
    }
    const _Save = async()=>{
        if(getValues('PackageName') == undefined || getValues('PackageName') === ""){
            Swal.fire({
                icon: 'error',
                title: 'ชื่อแพ็คเก็จ'
            });
            return;
        }

        if(getValues('PackageGroupID') == undefined || Number(getValues('PackageGroupID')) === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดเลือก กลุ่มแพ็คเก็จ'
            });
            return;
        }

        if(getValues('Price') == undefined || isNaN(getValues('Price')) || Number(getValues('Price')) === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบ ราคา'
            });
            return;
        }

        let _reqObj = {
            PackageId:(_Detail != null ? _Detail.PackageId:null),
            PackageCode: getValues('PackageCode'),
            PackageName: getValues('PackageName'),
            PackageGroupID : Number(getValues('PackageGroupID')),
            QuantityLimit:Number(getValues('QuantityLimit')),
            Price : Number(getValues('Price')),
            Description: getValues('Description'),
            Remark: getValues('Remark'),
            GLRefCode: getValues('GLRefCode'),
            PackagePrice : _PriceList,
            Menu : _FoodMenuList
        }

        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/Package/Save',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setModalState(false);
                _ClearValuAction();
                if(props.update != undefined){
                    try{
                        props.update(res.data.Data);
                    }catch(e){
                        console.log(e);
                    }
                }
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
                

            });
        });
    }

    const _ClearValuAction = ()=>{
        reset();
        setPriceList([]);
    }
    const _ClearValue = ()=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการล้างข้อมูล หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ตกลง',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            _ClearValuAction();
        });
    }
    const _AddPriceItem = ()=>{
        let _ItemList = [... _PriceList];
        const _Item = {
            PackagePriceId:null,
            Price : 0,
            Description:"",
            Remark : "",
            Quantity:0,
            Menu:[]
        }
        _FoodMenuList.find((v,i)=>{
            _Item.Menu.push({Type:v.Type,Name:v.Name,Quantity:v.Quantity});
        });
        _ItemList.push(_Item);
        setPriceList(_ItemList);
    }
    const _UpdatePriceItem = (Index,Value)=>{
        const _Item = [..._PriceList];
        let _ItemN = [];
        _Item.find((e,i) => {
            if(Index === i){
                _ItemN.push(Value);
            }else{
                _ItemN.push(e);
            }
        });
        setPriceList(_ItemN);
        console.log(_ItemN);
    }
    const _UpdatePriceMenuItem = (Index,Value,MIndex,MValue)=>{
        let _Obj = Value;
        let _Menu = [];
        Value.Menu.find((v,i)=>{
            if(i != MIndex){
                _Menu.push(v);
                return;
            }
            _Menu.push(MValue);
        });

        _Obj.Menu = _Menu;
        _UpdatePriceItem(Index,_Obj);
    }
    const _DeletePriceItem = (Index) =>{
        const _Item = [..._PriceList];
        let _ItemN = [];
        let _i=0;
        _Item.find((e,i) => {
            if(Index != i){
                _i++;
                e.LineNumber = _i;
                _ItemN.push(e);
            }
        });
        setPriceList(_ItemN);
    }
    const _UpdateMenuItem = (Index,Value)=>{
        const _Item = [..._FoodMenuList];
        let _ItemN = [];
        _Item.find((e,i) => {
            if(Index === i){
                _ItemN.push(Value);
            }else{
                _ItemN.push(e);
            }
        });
        setFoodMenuList(_ItemN);
    }
    const _UploadAddItem = (RefID)=>{
        _UploadGetFileLsit(RefID)
    }
    const _UploadGetFileLsit = async(RefID)=>{
        setFileList([]);
        API({method : "get",url:"/File/PACKAGE/"+RefID}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setFileList(res.data.Data);
        }).catch(res => {
            return;
        });
    }
      return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">{(_Detail != null ? "แก้ไขข้อมูลแพ็คเก็จ":"สร้างแพ็คเก็จ")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>
            <div className='modal-body' style={{'minHeight':'300px'}}>
                <Form onSubmit={handleSubmit(_Save)} className='row g-3'>
                    <div className='row mt-4'>
                    <div className="col-md-3">
                            <label className="form-label">รหัสแพ็คเก็จ</label>
                                <input type='text' className="form-control text-center" placeholder="รหัสแพ็คเก็จ" autoComplete="off"   {...register("PackageCode", { required: false })}/>
                        </div>
                        <div className="col-md-9">
                            <label className="form-label">ชื่อแพ็คเก็จ</label>
                                <input type='text' className="form-control" placeholder="ชื่อแพ็คเก็จ" autoComplete="off"   {...register("PackageName", { required: false })}/>
                        </div>
                       
                        
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-3">
                            <label className="form-label">กลุ่มแพ็คเก็จ</label>
                            <Controller name="PackageGroupID" control={control} rules={{required:false}} render={({field})=>(
                                <select className='form-control text-left' onChange={(e)=>field.onChange(e)} defaultValue={field.value} >
                                    <option value={0}>เลือกกลุ่มแพ็คเก็จ</option>
                                    {_PackageGroupList.map((v,i)=>(
                                     <option value={v.PackageGroupId}>{v.PackageGroupName}</option>   
                                ))}
                                </select>
                            )} />
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">จำนวนขั้นต่ำ</label>
                                <input type='number' maxLength={5} min={0} className="form-control text-end" placeholder="ราคา" autoComplete="off"   {...register("MinOrder", { required: false })}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">ราคา</label>
                                <input type='number' maxLength={5} min={0} className="form-control text-end" placeholder="ราคา" autoComplete="off"   {...register("Price", { required: false })}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">จำนวนจำกัด</label>
                            <input type='number' maxLength={5} min={0} className="form-control text-end" placeholder="จำนวนจำกัด" autoComplete="off"   {...register("QuantityLimit", { required: false })}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">รหัสทางบัญชี</label>
                            <input type='text' className="form-control text-left" placeholder="รหัสทางบัญชี" autoComplete="off"   {...register("GLRefCode", { required: false })}/>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-12">
                            <label className="form-label">รายละเอียด</label>
                            <Controller name="Description" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-12">
                            <label className="form-label">รายละเอียดภายใน</label>
                            <Controller name="Remark" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-12">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='text-center'>ประเภทอาหาร</th>
                                        <th className='text-center' style={{'width':'150px'}}>จำนวน (อย่าง)</th>
                                        <th className='text-center'>กลุ่มเมนู</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_FoodMenuList.map((v,i)=>(
                                        <tr key={i}>
                                            <td className='text-center'>{v.Name}</td>
                                            <td className='text-center'>
                                                <input type='number' min={0} value={v.Quantity} className='form-control text-center' onChange={(e)=>{
                                                    v.Quantity = e.target.value;
                                                    _UpdateMenuItem(i,v);
                                                }}/>
                                            </td>
                                            <td className='text-center'>
                                                <select className='form-control' value={v.FoodMenuGroupId} onChange={(e)=>{
                                                    v.FoodMenuGroupId = e.target.value;
                                                    _UpdateMenuItem(i,v);
                                                }}>
                                                    <option value={null}>เลือกเมนูอาหาร</option>
                                                    {_FoodGroupList.map((sv,i)=>(
                                                        <option value={sv.FoodMenuGroupId} selected={(v.FoodMenuGroupId === sv.FoodMenuGroupId?true:false)}>{sv.FoodMenuGroupName}</option>
                                                    ))}
                                                </select>
                                            </td>
                                        </tr>
                                    ))}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-md-12'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='text-center' style={{'width':'120px'}}>จำนวนหัว</th>
                                        <th className='text-center' style={{'width':'150px'}}>ราคา</th>
                                        <th className='text-center'>รายละเอียด</th>
                                        <th className='text-center'>หมายเหตุ</th>
                                        <th className='text-end'style={{'width':'100px'}}>
                                            <button type="button" className='btn btn-success btn-sm' onClick={()=>{_AddPriceItem();}}>+ เพิ่ม</button>
                                        </th>
                                    </tr>
                                </thead>
                                {_PriceList.map((v,i)=>(
                                    <tbody key={i}>
                                        <tr>
                                            <td className='text-center'>
                                                <input type='number' className='form-control text-center' min={0} value={v.Quantity} onChange={(e)=>{
                                                    v.Quantity = e.target.value;
                                                    _UpdatePriceItem(i,v);
                                                }}/>
                                            </td>
                                            <td className='text-center'>
                                                <input type='number' className='form-control text-end' min={0} value={v.Price} onChange={(e)=>{
                                                    v.Price = e.target.value;
                                                    _UpdatePriceItem(i,v);
                                                }}/>
                                            </td>
                                            <td className='text-center'>
                                                <textarea className='form-control' value={v.Description} onChange={(e)=>{
                                                    v.Description = e.target.value;
                                                    _UpdatePriceItem(i,v);
                                                }}></textarea>
                                            </td>
                                            <td className='text-center'>
                                                <textarea className='form-control' value={v.Remark} onChange={(e)=>{
                                                    v.Remark = e.target.value;
                                                    _UpdatePriceItem(i,v);
                                                }}></textarea>
                                            </td>
                                            <td className='text-end'>
                                                <div className='btn-group btn-group-sm'>
                                                    <button type="button" className='btn btn-danger' onClick={(e)=>{_DeletePriceItem(i);}}>ลบ</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='' colSpan={5}>
                                                <div className='row justify-content-center'>
                                                {v.Menu.map((vM,iM)=>(
                                                    <div className='col-lg-1' key={iM}>
                                                        <input type='number' min={0} title={vM.Name} className='form-control' value={vM.Quantity} onChange={(e)=>{
                                                            vM.Quantity = e.target.value;
                                                            //_UpdateMenuItem(i,v);
                                                            _UpdatePriceMenuItem(i,v,iM,vM);
                                                    }}/>
                                                    </div>
                                                ))}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                                
                            </table>
                        </div>
                    </div>
                    {(_Detail != null?<>
                    <div className='row mt-4 mb-2'>
                        <div className='col-lg-12'>
                            <div className='card'>
                                <div className='card-header text-end'>
                                    <div className='btn-group btn-group-sm'>
                                        <button className='btn btn-success' type="button" onClick={()=>{_UploadRef.current.Open('PACKAGE',_Detail.PackageId,undefined,(e)=>{_UploadAddItem(_Detail.PackageId);})}}>Upload</button>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    {_FileList.map((v,i)=>(
                                        <div className='row mt-2 mb-2' key={i}>
                                            <div className='col-lg-3'>
                                                <a href='#' onClick={(e)=>{_FilePreviewRef.current.Open(v);}}>
                                                    <img className='form-control' src={APIURL()+'/File/'+GetAuthorization()+'/'+v.FileID+'/Preview'}/>
                                                </a>
                                            </div>
                                            <div className='col-lg-6 fs-6'>{v.FileName}</div>
                                            <div className='col-lg-3 text-center'>
                                                <button className='btn btn-danger' type='button' onClick={()=>{DeleteFile(v.FileID,()=>{_UploadGetFileLsit(_Detail.PackageId);});}}>ลบ</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    </>:'')}
                    <div className='row mt-3'>
                        <div className='col-md-12 mt-4 text-end'>
                            <div className='btn-group btn-group-lg'>
                                <button type="button" className='btn btn-danger' disabled={(_Detail != null ? true:false)} onClick={()=>{_ClearValue();}}>ล้างข้อมูล</button>
                                <button type="submit" className='btn btn-success'>บันทึก</button>
                            </div>
                        </div>
                    </div>
                    </Form>
            </div>
        </Modal>
        <UploadCard ref={_UploadRef} />
        <FilePreviewCard ref={_FilePreviewRef} />

        </>
      )
});
export default React.memo(PackageCreate);