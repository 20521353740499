import React,{forwardRef,useImperativeHandle,useEffect, useState } from 'react';
import { Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import moment from 'moment';
import { NumberFormat } from '../../Utils/Function';
const ExampleCard = forwardRef((props,ref) =>{
    const [_ModalState,setModalState] = useState(false);
    const [_List,setList]= useState([]);
    useEffect(() => {
        (async () => {
      
        })();

        return ()=>{ 
        }
    }, []);
    
    useImperativeHandle(ref, () => ({
        NewItem(){
            setList([]);
            _LoadPromotion();
            setModalState(true);
        }
    }));
    const _LoadPromotion = async()=>{
        setList([]);
        API({method : "get",url:"/Promotion/List"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setList(res.data.Data);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: res.data.Message,
            });
            return;
        });
    }
    const _NewItemPromotion = (val)=>{
        if(props.newitem != undefined){
            try{
                props.newitem(val);
            }catch(e){
                console.log(e);
            }
        }
        setModalState(false);
    }
    return (
        <>
            <Modal isOpen={_ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
                <div className="modal-header">
                    <h5 className="modal-title">เลือกโปรโมชั่น</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
                </div>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-12'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='text-center'>รหัส</th>
                                        <th className='text-center'>ชื่อโปร</th>
                                        <th className='text-center'>รายละเอียด</th>
                                        <th className='text-center'>ส่วนลด</th>
                                        <th className='text-center'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_List.map((v,i)=>(
                                    <tr key={i}>
                                        <td className='text-center'>{v.PromotionCode}</td>
                                        <td className='text-left'>{v.PromotionName}</td>
                                        <td className='text-left'>{v.Description}</td>
                                        <td className='text-end'>{NumberFormat(v.DiscountValue)}</td>
                                        <td className='text-center'>
                                            <button className='btn btn-success btn-sm' onClick={()=>{_NewItemPromotion(v);}}>เลือก</button>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );

});

export default React.memo(ExampleCard)