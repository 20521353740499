import React,{useRef,useState,useEffect} from 'react';
import {DataTable,QuotationInfoCard} from '../../Components'
import { Controller, set, useForm } from "react-hook-form";
import { createRoot } from 'react-dom/client';
import { DateFormat,APIURL, DateFormatJSON } from '../../Utils/Function';
import {QuotationType,QuotationTypeName,OrderStatus,OrderStatusList} from '../../Utils/Status';
import Swal from 'sweetalert2';
import API from '../../API';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import thLocale from '@fullcalendar/core/locales/th';

import { Calendar } from '@fullcalendar/core/index.js';
const PageCalendar =()=> {
    const { register, getValues, setValue } = useForm();
    const [_EventList,setEventList] = useState([]);
    const [_DateInfo,setDateInfo] = useState(null);
    const [_CalendarType,setCalendarType] = useState(0);
    const [_AccountType,setAccountType] = useState(0);
    const [_CalendarStatus,setCalendarStatus] = useState(0);
    const _QuotationInfoCard = useRef();
    useEffect(() => {
        (async () => {
            //await _LoadQuotationStatus();
        })();

        return ()=>{ }
    }, []);
    const handleEventClick = (arg) => {
        if(arg.event._def.extendedProps.CalendarID === undefined) return;
        const _CalendarID = arg.event._def.extendedProps.CalendarID;
        _GetEvent(_CalendarID);
    }
    const _Search = async() =>{
        _LoadEvent(_DateInfo.start,_DateInfo.end);
    }
    const _LoadEvent = async(Start,End)=>{
        Swal.showLoading();
        API({method : "get",url:"/Calendar/Event?StartDate="+DateFormatJSON(Start,false)+'&EndDate='+DateFormatJSON(End,false)+'&CalendarType='+_CalendarType+'&AccountType='+_AccountType+'&CalendarStatus='+_CalendarType}).then(res=>{
            if (res.status !== 200) {
                Swal.close();
                return;
            }
    
            if (!res.data.Status) {
                Swal.close();
                return;
            }
            setEventList(res.data.Data);
            Swal.close();
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: res.data.Message,
            });
            return;
        });
    }

    const _GetEvent = async(CalendarID) =>{
        Swal.showLoading();
        API({method : "get",url:"/Calendar/Event/"+CalendarID}).then(res=>{
            if (res.status !== 200) {
                Swal.close();
                return;
            }
    
            if (!res.data.Status) {
                Swal.close();
                return;
            }
            Swal.close();
            switch(res.data.Data.RefType){
                case 'QEVEN':
                    _LoadQuotationEvent(res.data.Data.RefId);
                break;
            }
        }).catch(res => {
            Swal.close();
            return;
        });
    }
    const _LoadQuotationEvent = async(QuotationEventID) =>{
        Swal.showLoading();
        API({method : "get",url:"/Quotation/Event/"+QuotationEventID}).then(res=>{
            Swal.close();
            if (res.status !== 200) {
                return;
            }
            if (!res.data.Status) {
                return;
            }
            _QuotationInfoCard.current?.Show(res.data.Data.QuotationId);
        }).catch(res => {
            Swal.close();
            return;
        });
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-2 mb-2 justify-content-end'>
                    <div className='col-lg-2'>
                        <div className="input-group mb-3">
                            <select className='form-control text-center' value={_AccountType} onChange={(e)=>{setAccountType(e.target.value);}} >
                                <option value={0}>ประเภท</option>
                                <option value={1}>บริษัท</option>
                                <option value={2}>ร้าน</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className="input-group mb-3">
                            <select className='form-control text-center' value={_CalendarType} onChange={(e)=>{setCalendarType(e.target.value);}} >
                                <option value={0}>ประเภทใบเสนอราคา</option>
                                {QuotationType.map((v,i)=>(
                                        <option key={i} value={v}>{QuotationTypeName(v)}</option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className="input-group mb-3">
                            <select className='form-control text-center' value={_CalendarType} onChange={(e)=>{setCalendarType(e.target.value);}} >
                                <option value={0}>สถานะ</option>
                                {OrderStatusList.map((v,i)=>(
                                        <option key={i} value={v}>{OrderStatus(v)}</option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-1 text-end'>
                        <button type='button' style={{'width':'80px'}} className='btn btn-info text-white' onClick={()=>{_Search();}}>ค้นหา</button>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-12'>
                        <FullCalendar
                        plugins={[ dayGridPlugin,interactionPlugin ]}
                        locale={thLocale}
                        initialView="dayGridMonth"
                        eventClick={handleEventClick}
                        datesSet={(dateInfo) => {
                            setDateInfo({...dateInfo});
                            _LoadEvent(dateInfo.start,dateInfo.end);
                        }}
                        events={_EventList}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
        <QuotationInfoCard ref={_QuotationInfoCard}/>

        </>
    )
}
export default React.memo(PageCalendar)