import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import { Controller, useForm } from "react-hook-form";

import API from '../../API';
import { DateFormat,DateFormatJSON, DateThai,MobileFormat, NumberFormat,CleanMobile, TimeFormat,APIURL,GetAuthorization, KeyDownNumber } from '../../Utils/Function';
import {SearchSubDistrict,SearchCustomer} from '../../Utils/Search';
import parse from 'html-react-parser'
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import {UploadCard,DeleteFile,FilePreviewCard} from '../../Components'

const PromotionInfo = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();

    const [ModalState,setModalState] = useState(false);
    const [_Detail,setDetail] = useState(null);
    const _UploadRef = useRef();
    const [_FileList,setFileList] = useState([]);
    const _FilePreviewRef = useRef();
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(PromotionID){
            _ClearValuAction();
            setDetail(null);
            _Initial();
            if(PromotionID === undefined){
                setModalState(true);
            }else{
                _LoadDetail(PromotionID);
            }        }
      }));
    const _Initial = ()=>{
        
    }
    const _LoadDetail = async (PromotionID)=>{
        setFileList([]);
        API({method : "get",url:"/Promotion/"+PromotionID}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            const _Res = res.data.Data;
            setDetail(res.data.Data);
            setValue('PromotionCode',_Res.PromotionCode);
            setValue('PromotionName',_Res.PromotionName);
            setValue('Description',_Res.Description);
            setValue('DiscountType',_Res.DiscountType);
            setValue('DiscountValue',_Res.DiscountValue);
            setValue('Remark',_Res.Remark);

            //_UploadGetFileLsit(res.data.Data.ProductId)
            setModalState(true);
        }).catch(res => {
            if(res.response.data != undefined){
                Swal.fire({
                    icon: 'error',
                    title: res.code,
                    text: res.response.data.Message
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                });
            }
            return;
        });
    }
    
    const _Save = async()=>{

        if(getValues('PromotionName') == undefined || getValues('PromotionName') === ""){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบ ชื่อโปรโมชั่น'
            });
            return;
        }

        if(getValues('PromotionCode') == undefined || getValues('PromotionCode') === ""){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุรหัสโปรโมชั่น'
            });
            return;
        }

        if(getValues('DiscountType') == undefined || Number(getValues('DiscountType')) === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดเลือก รูปแบบส่วนลด'
            });
            return;
        }
        
        let _reqObj = {
            PromotionID:(_Detail != null ? _Detail.PromotionID:null),
            PromotionCode: getValues('PromotionCode'),
            PromotionName: getValues('PromotionName'),
            Description: getValues('Description'),
            DiscountType : Number(getValues('DiscountType')),
            DiscountValue : Number(getValues('DiscountValue')),
            Remark: getValues('Remark'),
        }

        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/Promotion/Save',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setModalState(false);
                _ClearValuAction();
                if(props.update != undefined){
                    try{
                        props.update(res.data.Data);
                    }catch(e){
                        console.log(e);
                    }
                }
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
            });

        });

    }

    const _ClearValuAction = ()=>{
        reset();
    }
    const _ClearValue = ()=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการล้างข้อมูล หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ตกลง',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            _ClearValuAction();
        });
    }
      return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">{(_Detail != null ? "แก้ไขโปรโมชั่น":"สร้างโปรโมชั่น")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>
            <div className='modal-body' style={{'minHeight':'300px'}}>
                <Form onSubmit={handleSubmit(_Save)} className='row g-3'>
                    <div className='row mt-4'>
                        <div className="col-md-3">
                            <label className="form-label">รหัสโปรโมชั่น</label>
                                <input type='text' className="form-control text-center" placeholder="รหัสโปรโมชั่น" autoComplete="off"   {...register("PromotionCode", { required: false })}/>
                        </div>
                        <div className="col-md-9">
                            <label className="form-label">ชื่อโปรโมชั่น</label>
                                <input type='text' className="form-control" placeholder="ชื่อโปรโมชั่น" autoComplete="off"   {...register("PromotionName", { required: false })}/>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-12">
                            <label className="form-label">รายละเอียด</label>
                            <Controller name="Description" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className="col-md-3">
                            <label className="form-label">รูปแบบส่วนลด</label>
                            <Controller name="DiscountType" control={control} rules={{required:false}} render={({field})=>(
                                <select className='form-control text-left' onChange={(e)=>field.onChange(e)} defaultValue={field.value} >
                                    <option value={0}>เลือกรูปแบบส่วนลด</option>
                                    <option value={1}>ส่วนลด</option>
                                    <option value={2}>ส่วนลดเปอร์เซ็น</option>
                                    <option value={3}>ฟรีค่าขนส่ง</option>
                                    <option value={4}>ของแถม</option>
                                </select>
                            )} />
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">ส่วนลด</label>
                            <input type='text' className="form-control text-center" onKeyDown={(e)=>{KeyDownNumber(e)}} placeholder="ส่วนลด" autoComplete="off"   {...register("DiscountValue", { required: false })}/>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-12">
                            <label className="form-label">หมายเหตุ</label>
                            <Controller name="Remark" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-12 mt-4 text-end'>
                            <div className='btn-group btn-group-lg'>
                                <button type="button" className='btn btn-danger' disabled={(_Detail != null ? true:false)} onClick={()=>{_ClearValue();}}>ล้างข้อมูล</button>
                                <button type="submit" className='btn btn-success'>บันทึก</button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
        </>
      )
});
export default React.memo(PromotionInfo);